:root {
  --swiper-navigation-size: 0.229167rem;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0 - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-feature-settings: ;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0.052083rem;
  right: auto;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: 'prev';
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 0.052083rem;
  left: auto;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: 'next';
}

.swiper-button-prev.swiper-button-white,
.swiper-button-next.swiper-button-white {
  --swiper-navigation-color: #fff;
}

.swiper-button-prev.swiper-button-black,
.swiper-button-next.swiper-button-black {
  --swiper-navigation-color: #000;
}

.swiper-button-lock {
  display: none;
}